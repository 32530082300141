import { MessageDescriptor } from 'react-intl';
import { ReactComponent as IconArrowLeft } from '@whitelabel/media/icons/small/arrow-left.svg';
import { INavItem } from '@whitelabel/helpers/types';
import { PaymentsType } from './types';
import claimsPortalMessages from './messages/commonMsg';

export const CP_ROOT = (process.env.VITE_CP_ROOT || process.env.NEXT_PUBLIC_CP_ROOT) as 'xcover' | 'rentalcover';

export const STATUS_ACTION_REQUIRED = 'ACTION REQUIRED';

export const CLAIM_CENTRE_STATUS = 'claim-centre-status';

export const buttonLabelMessage: Record<Exclude<PaymentsType, 'refund'>, MessageDescriptor> = {
  payout: claimsPortalMessages.getMyPayment,
  charge: claimsPortalMessages.payExcess,
};

export const DEFAULT_MAX_SIZE = 10240;

export const REVIEW_SECTION_NAME = 'review';

export const BW_EXP_COVERAGE_VARIANT_NAMES = ['new-coverage-page', 'no-coverage-page'] as const;

export const CLAIM_SUBMITTED_STATUS = 'claimSubmittedStatus';

export const FNOL_FLOW_NAV_ITEMS: INavItem[] = [
  {
    key: 'saveProgressButton',
    messageKey: 'saveProgressButtonLabel',
    name: 'Back to your claims',
    path: '/claims',
    icon: IconArrowLeft,
  },
];

export const PAYMENT_FLOW_NAV_ITEMS: INavItem[] = [
  {
    key: 'claimPaymentButton',
    messageKey: 'claimPaymentButtonLabel',
    name: 'Back to your claim',
    path: '/claims/:claimReference/details',
    icon: IconArrowLeft,
  },
];
