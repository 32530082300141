import styled from 'styled-components';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { fontFamily } from '@whitelabel/helpers/style/type';

export const StyledAppLayout = styled.div<{ $isNavOpen: boolean; $rtl: boolean }>`
  direction: ${({ $rtl }) => ($rtl ? 'rtl' : 'ltr')};

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: ${({ $isNavOpen }) => ($isNavOpen ? 'fixed' : 'initial')};

  ${mediaBreakpointUp('lg')`
    position: initial;
  `}

  main {
    background-color: ${themeColor('bgLight')};

    body.rentalcover-theme & {
      padding-top: 1rem;
      padding-bottom: 4rem;

      ${mediaBreakpointUp('sm')`
        padding-top: 2rem;
        padding-bottom: 5rem;
      `}

      ${mediaBreakpointUp('md')`
        padding-top: 3rem;
        padding-bottom: 8rem;
      `}
    }
  }

  dt {
    font-family: ${fontFamily('sanSerif')};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: none;
  }

  // To reduce layout shift
  .secondary-nav {
    display: none;
    min-height: 0;
    ${mediaBreakpointUp('lg')`
      display: block;
      min-height: 3rem;
    `}
  }
`;

export const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${themeColor('primaryBg')};
  grid-row-gap: 2rem;
`;
