import { lazy } from 'react';

export const ClaimsLanding = lazy(() => import('../containers/pages/ClaimsLanding'));
export const ClaimDetails = lazy(() => import('../containers/pages/ClaimDetails'));
export const ClaimDetailsDocument = lazy(() => import('../containers/pages/ClaimDetailsDocument'));
export const ClaimSubmitted = lazy(() => import('../containers/pages/ClaimSubmitted'));
export const ClaimSelectorInitial = lazy(() => import('../containers/pages/ClaimSelectorInitial'));
export const ClaimSelector = lazy(() => import('../containers/pages/ClaimSelector'));
export const FNOLSections = lazy(() => import('../containers/pages/FNOLSections'));
export const PreDocumentUploadPage = lazy(() => import('../containers/pages/FNOLDocumentsUploader'));

export const authRoutes = [
  {
    key: 'claimsLanding',
    path: '/claims',
    exact: true,
    component: ClaimsLanding,
  },
  {
    key: 'claimSubmitted',
    path: '/claims/fnol/submitted',
    exact: true,
    component: ClaimSubmitted,
  },
  {
    key: 'claimSelectorInitial',
    path: '/claims/fnol/claim-selector',
    exact: true,
    component: ClaimSelectorInitial,
  },
  {
    key: 'claimSelector',
    path: '/claims/fnol/claim-selector/:decisionPointId',
    exact: true,
    component: ClaimSelector,
  },
  {
    key: 'fnolSections',
    path: '/claims/fnol/sections/:sectionNames(.*)?',
    component: FNOLSections,
  },
  {
    key: 'PreDocumentUploadPage',
    path: '/claims/fnol/documents/:documentName',
    component: PreDocumentUploadPage,
  },
  {
    key: 'claimDetails',
    path: '/claims/:claimReference/details',
    exact: true,
    component: ClaimDetails,
  },
  {
    key: 'claimDetailsDocument',
    path: '/claims/:claimReference/documents/:documentName',
    exact: true,
    component: ClaimDetailsDocument,
  },
];
