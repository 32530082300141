import React from 'react';
import { useIntl } from 'react-intl';
import {
  StyledNavIcon,
  StyledNavButton,
  StyledNavIconWrapper,
} from '@whitelabel/rentalcover-shared/styled/StyledNavButton';
import { getButtonProps } from '@whitelabel/rentalcover-shared/helpers/button';

interface INavItemProps {
  icon?: React.FunctionComponent<React.PropsWithChildren<unknown>>;
  name: string;
  path?: string;
  onClick?: () => void;
  className?: string;
}

const NavItem = ({ icon, name, className, path, onClick }: INavItemProps) => {
  const { locale } = useIntl();

  return (
    <StyledNavButton
      className={className}
      buttonType="tertiary"
      {...getButtonProps({ handleClick: onClick, link: path, locale })}
    >
      {icon && (
        <StyledNavIconWrapper>
          <StyledNavIcon as={icon} />
        </StyledNavIconWrapper>
      )}
      {name}
    </StyledNavButton>
  );
};

export default NavItem;
