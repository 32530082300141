import styled from 'styled-components';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { themeColor, color } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { transitions } from '@whitelabel/helpers/style/get';
import { fontStyle, fontWeight } from '@whitelabel/helpers/style/type';

// Base dropdown styles. Modify on component level by wrapping with styled()

export const BaseStyledDropdownToggle = styled(DropdownToggle)`
  text-transform: uppercase;

  &&& {
    display: flex;
    align-items: center;
    padding: 0;
    text-decoration: none;
    ${fontStyle('base')};
    font-weight: ${fontWeight('black')};
    button:focus {
      outline: none;
    }

    font-size: 0.75rem;
    ${mediaBreakpointUp('lg')`
      font-size: 1rem;
    `}
  }
  &&&:hover {
    box-shadow: none;
    color: ${themeColor('secondary')};
  }
`;

export const BaseStyledDropdown = styled(UncontrolledDropdown)`
  display: none;
  ${mediaBreakpointUp('lg')`
    padding: 0;
    border-top: 0;
    display: block; 
  `}

  &.show > ${BaseStyledDropdownToggle} > svg,
  ${BaseStyledDropdownToggle} > svg:hover {
    circle:first-of-type {
      fill: ${themeColor('primary')};
      stroke: ${color('black')};
    }

    path {
      stroke: ${color('black')};
    }
  }
`;

export const BaseStyledDropdownMenu = styled(DropdownMenu)`
  && {
    display: block;
    margin-top: 1.25rem;
    padding: 1rem;
    transform: scale(0);
    opacity: 0;
    transition: opacity ${transitions('duration')} ease 0s, transform 0s linear 0.9s;

    > div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    &.dropdown-menu-end {
      right: -0.5rem;
      left: auto;
    }

    &.dropdown-menu.show {
      transform: scale(1);
      opacity: 1;
      transition: transform 0s linear 0s, opacity ${transitions('duration')} ease 0.01s;
    }
  }
`;

export const BaseStyledDropdownItem = styled(DropdownItem)`
  && {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0;
    color: ${themeColor('subheading')};
    transition: color ${transitions('duration')} ease-in-out;
    text-wrap: wrap;
    width: fit-content;

    &:hover,
    &:active,
    &.active {
      color: ${themeColor('secondary')};
      background: none;
      box-shadow: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
