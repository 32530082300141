import { defineMessages } from 'react-intl';

export default defineMessages({
  generalErrorTitle: {
    id: 'pages.error.generalErrorTitle',
    defaultMessage: 'Oops',
  },
  generalErrorText: {
    id: 'pages.error.generalErrorText',
    defaultMessage: `Something’s gone wrong. But don’t worry, click the button below to return to the homepage.`,
  },
  generalErrorButtonText: {
    id: 'pages.error.backButtonText',
    defaultMessage: `Back To Home`,
  },
  partnersLinks: {
    id: 'partial.navbar.partners',
    defaultMessage: 'Partners',
  },
  apiPartnersSubmenu: {
    id: 'partial.navbar.menu.partner.submenu',
    defaultMessage: 'API Partners',
  },
  affiliateSubmenu: {
    id: 'partial.navbar.menu.affiliate.submenu',
    defaultMessage: 'Affiliates',
  },
  helpArticleLinks: {
    id: 'partial.navbar.menu.help.title',
    defaultMessage: 'Help articles',
  },
  explainSubmenu: {
    id: 'partial.navbar.menu.explain.submenu',
    defaultMessage: 'Rental Car Insurance Explained',
  },
  howItWorksSubmenu: {
    id: 'partial.navbar.menu.works.submenu',
    defaultMessage: 'How RentalCover.com works',
  },
  whySubmenu: {
    id: 'partial.navbar.menu.why.submenu',
    defaultMessage: 'Why use RentalCover.com',
  },
  howItWorksDropdownLinks: {
    id: 'partial.navbar.rentalguide',
    defaultMessage: 'How it works',
  },
  rentalGuideLinks: {
    id: 'partial.navbar.menu.rentalguide.title',
    defaultMessage: 'Rental Guide',
  },
  helpLinkText: {
    id: 'partial.navbar.help',
    defaultMessage: 'Help',
  },
  claimsLinkText: {
    id: 'partial.navbar.claims',
    defaultMessage: 'Claims',
  },
  yourProtectionLinkText: {
    id: 'partial.navbar.menu.bookings.submenu',
    defaultMessage: 'Your protection',
  },
  yourProfileLinkText: {
    id: 'partial.navbar.menu.profile.submenu',
    defaultMessage: 'Your profile',
  },
  yourClaimsLinkText: {
    id: 'partial.navbar.menu.claims.submenu',
    defaultMessage: 'Your claims',
  },
  logoutLinkText: {
    id: 'generic.button.logout',
    defaultMessage: 'Logout',
  },
  myAccountLinkText: {
    id: 'navBar.myAccountLinkText',
    defaultMessage: 'My Account',
  },
  getQuoteButton: {
    id: 'partial.navbar.quote',
    defaultMessage: 'Get a quote',
  },
  backButtonText: {
    id: 'generic.button.back',
    defaultMessage: `Back`,
  },
  selectYourLanguage: {
    id: 'partial.modal.language.language.heading',
    defaultMessage: 'Select Your Language',
  },
  languageWarningMessage: {
    id: 'partial.modal.language.language.warning',
    defaultMessage: 'Translations may impact content quality if you select a language other than English.',
  },
  continentEurope: {
    id: 'generic.label.continent.europe',
    defaultMessage: 'Europe',
  },
  continentNorthAmerica: {
    id: 'generic.label.continent.northAmerica',
    defaultMessage: 'North America',
  },
  continentSouthAmerica: {
    id: 'generic.label.continent.southAmerica',
    defaultMessage: 'South America',
  },
  continentAsia: {
    id: 'generic.label.continent.asia',
    defaultMessage: 'Asia',
  },
  continentOceania: {
    id: 'generic.label.continent.oceania',
    defaultMessage: 'Oceania',
  },
  continentAntartica: {
    id: 'generic.label.continent.antartica',
    defaultMessage: 'Antartica',
  },
  continentAfrica: {
    id: 'generic.label.continent.africa',
    defaultMessage: 'Africa',
  },
});
