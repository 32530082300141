import React from 'react';
import { useIntl } from 'react-intl';
import {
  StyledDropdownItem,
  StyledDropdownItemIcon,
} from '@whitelabel/rentalcover-shared/styled/StyledCustomerDropdown';
import { getButtonProps } from '@whitelabel/rentalcover-shared/helpers/button';

interface IDropdownItemProps {
  name: string;
  path: string;
  icon?: React.FunctionComponent<React.PropsWithChildren<unknown>>;
  dataAnalytics?: string;
}

const DropdownItem = ({ name, path, icon, dataAnalytics }: IDropdownItemProps): JSX.Element => {
  const { locale } = useIntl();

  return (
    <StyledDropdownItem
      data-test-id="CustomerDropdown-item"
      data-analytics={dataAnalytics}
      {...getButtonProps({ link: path, locale })}
    >
      {icon && <StyledDropdownItemIcon as={icon} data-test-id="CustomerDropdown-itemIcon" />}
      {name}
    </StyledDropdownItem>
  );
};

export default DropdownItem;
