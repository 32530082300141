import styled from 'styled-components';
import { DropdownItem } from 'reactstrap';
import { color, themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import {
  BaseStyledDropdown,
  BaseStyledDropdownItem,
  BaseStyledDropdownMenu,
  BaseStyledDropdownToggle,
} from './StyledBaseDropdown';

/**
 * Apply same style to StyledNavButton even if this needs to be derived from
 * DropdownToggle component (to toggle the corresponding dropdown)
 */
export const StyledDropdownToggle = styled(BaseStyledDropdownToggle)`
  padding: 0;
  display: flex;
  align-items: center;
  width: auto;
  white-space: nowrap;
  color: ${themeColor('subheading')};
  &&& {
    font-size: 0.875rem;
    :hover,
    :active {
      color: ${themeColor('heading')};
      svg {
        path {
          fill: ${themeColor('heading')};
        }
      }
    }
    :focus {
      color: ${themeColor('subheading')};
    }
  }

  // Force "Button" component not to set direction so that margin-inline on icons work as intended
  direction: unset;
  flex-direction: unset;

  justify-content: left;

  margin-bottom: 1rem;

  ${mediaBreakpointUp('lg')`
    margin-bottom: 0;
  `}
`;

export const StyledDropdown = BaseStyledDropdown;
export const StyledDropdownMenu = BaseStyledDropdownMenu;
export const StyledDropdownItem = BaseStyledDropdownItem;

export const StyledDropdownItemDivider = styled(DropdownItem).attrs(() => ({ divider: true }))`
  margin: 0 0 0.5rem;
  border-color: ${color('paleGrayBlue')};
`;

export const StyledDropdownItemIcon = styled.svg.attrs(() => ({ 'aria-hidden': 'true' }))`
  display: block;
  margin-inline-end: 0.5rem;
`;
