import React, { StrictMode, Suspense } from 'react';
import moment from 'moment';
import { LocaleObject, setLocale } from 'yup';
import { Provider as ReduxProvider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import * as FullStory from '@fullstory/browser';
import { yupMessages } from '@whitelabel/helpers/forms/messages';
import { GlobalStyle } from '@whitelabel/component-library';
import { theme } from '@whitelabel/rentalcover-shared/theme';
import { MediaContextProvider, mediaStyles } from '@whitelabel/helpers/media';
import { StyledLoading } from '@whitelabel/rentalcover-shared/styled';
import { site } from '@whitelabel/helpers/site';
import { CURRENT_LOCALE } from '@whitelabel/rentalcover-shared/helpers/constants';
import { loadCountries } from '@whitelabel/helpers/utils';
import { loadPolyfills, loadMomentLocale } from '@whitelabel/xcover-shared/helpers/polyfills';
import ErrorBoundary from './containers/ErrorBoundary';
import App from './containers/App';
import { store } from './store';
import '@whitelabel/rentalcover-shared/scss/index.scss';
import { localePrefix } from './helpers/constants';
import IntlConfigProvider from './components/IntlConfigProvider';
import SharedRouterProvider from './components/SharedRouterProvider';
import { detectLanguage } from './helpers/site';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  ignoreErrors: [/(\$|jQuery) is not defined/i, /Can't find variable: (\$|jQuery)/i],
});

if (!window.isHeadless) {
  FullStory.init({ orgId: '34DW0', devMode: import.meta.env.DEV });
}

void (async function bootstrapApp() {
  const locale = detectLanguage();
  if (!locale) return;
  site.locale = locale;

  await loadPolyfills(locale);
  await loadMomentLocale(locale);
  site.countries = await loadCountries(locale);
  setLocale(yupMessages as LocaleObject);
  sessionStorage.setItem(CURRENT_LOCALE, locale);

  if (locale === 'ar') {
    moment.updateLocale('ar', {
      weekdaysMin: 'الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت'.split('_'),
      preparse: (string: string) => string,
      postformat: (string: string) => string,
    });
  }

  const root = createRoot(document.getElementById('root')!);
  root.render(
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <style type="text/css">{mediaStyles}</style>
        <MediaContextProvider>
          <GlobalStyle />
          <StrictMode>
            <BrowserRouter>
              <Switch>
                <Route path={localePrefix}>
                  <IntlConfigProvider>
                    <SharedRouterProvider>
                      <Suspense fallback={<StyledLoading />}>
                        <ErrorBoundary>
                          <App />
                        </ErrorBoundary>
                      </Suspense>
                    </SharedRouterProvider>
                  </IntlConfigProvider>
                </Route>
              </Switch>
            </BrowserRouter>
          </StrictMode>
        </MediaContextProvider>
      </ThemeProvider>
    </ReduxProvider>,
  );
})();
