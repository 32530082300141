interface IButton {
  link?: string;
  newWindow?: boolean;
  locale: string;
  handleClick?: () => void;
}

type ButtonProps =
  | {
      tag: 'a';
      href: string;
      rel: string;
      target?: string;
    }
  | {
      onClick: () => void;
    };

/** This is similar to getButtonProps without prefetching.
 * Use <Link href="/[slug]" as={`/${slug}`}> for prefetching
 * Related issue:
 * - https://stackoverflow.com/questions/62341093/prefetching-not-working-on-dynamic-routes-next-js
 * - https://github.com/vercel/next.js/discussions/16596
 */
export const getButtonProps = ({ handleClick, link, newWindow = false, locale }: IButton): ButtonProps | void => {
  if (link)
    return {
      tag: 'a',
      href: `/${locale}${link}`,
      rel: 'noopener noreferrer',
      target: newWindow ? '_blank' : undefined,
    };

  if (handleClick) {
    return {
      onClick: handleClick,
    };
  }

  return undefined;
};
